import request from "@/utils/request";
//添加文章
export function add(data) {
  return request({
    url: `/Article/Add`,
    method: "post",
    data,
  });
}

//修改文章
export function update(data) {
  return request({
    url: `/Article/Update`,
    method: "post",
    data,
  });
}

//获取文章详情
export function get(data) {
  return request({
    url: `/Article/Get`,
    method: "post",
    data,
  });
}

//上传图片
export function uploadImg(data) {
  return request({
    url: `/Common/UploadImg`,
    method: "post",
    data,
  });
}
//获取公众号文章
export function getMpArticle(data) {
  return request({
    url: `/Article/GetMpArticle`,
    method: "post",
    data,
  });
}

//code解析
export function getWxUserBaseInfo(data) {
  return request({
    url: `/WxOpen/MpOAuthUpdateUserNameAvatar`,
    method: "post",
    data,
  });
}
