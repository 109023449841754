<template></template>

<script>
//测试AppId
// const appId = 'wxa9dadd63bb592073';
//正式AppId
const appId = "wx548720ab9b10d6ea";
const urlPrefixNoAdmin = "https://api.xxzbsc.com"; //正式api地址
//    const urlPrefixNoAdmin = "http://t.szlsyjsq.com"; //测试api地址
export default {
  appId,
  urlPrefixNoAdmin,
};
</script>

<style scoped></style>
